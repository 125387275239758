<template>
	<form @submit.prevent="submit">
		<div class="mb-3">
			<input v-model="email"
			       @focusout="validateEmail"
			       id="email"
			       class="input-field"
			       type="email"
			       placeholder="Enter your email address"
			       required>
			<div class="mt-2">
				<small v-if="error"
				       class="form-text mt-2 font-weight-bold text-danger text-left">
					<img src="../assets/error.png" height="15" width="15" alt="Error">
					{{ error }}
				</small>
			</div>
		</div>

		<button v-bind:disabled="error"
		        type="submit"
		        class="btn btn-primary btn-block">
			<span v-if="loading" class="spinner-border spinner-border-sm"></span>
			{{ submitButtonText }}
		</button>
	</form>
</template>

<script>
import statics from "../static";
import auth from "../auth";

export default {
	name: "ForgotPasswordForm",
	props: {
		forgotEmail: {
			type: String,
			required: false
		}
	},
	computed: {
		submitButtonText() {
			return this.loading ?
				'Loading...' :
				'Continue'
		}
	},
	data() {
		return {
			error: null,
			loading: false,
			email: this.forgotEmail
		}
	},
	methods: {
		validateEmail() {

			const re = /\S+@\S+\.\S+/
			let emailValidated = re.test(this.email)

			if (!this.email) {
				this.error = null
				return false
			}

			if (!emailValidated) {
				this.error = statics.email_address_format
			} else {
				this.error = null
			}

			return emailValidated
		},
		submit() {
			this.error = null
			this.loading = true

			auth.sendResetCode({
				email: this.email
			})
				.then(data => {
					this.loading = false

					this.$router.push({
						name: "ResetPassword",
						params: {
							email: this.email
						}
					})
				})
				.catch(error => {
					this.loading = false
					let errorData = error.response.data.errors
					this.error = errorData['email'][0]
				})
		},
	}
}
</script>

<style scoped>

</style>